<template>
  <div class="textmessage">
    <div class="textmessage_top">
      <span @click="fbuton">
        <i class="el-icon-arrow-left"></i> 返回&nbsp;/
      </span>
      <span>邮件通知配置</span>
    </div>

    <div class="textmessage_bottom">
      <el-form style="width:540px; margin-top:20px;" ref="form" :model="form" label-width="80px">
        <div>
          <div class="textmessage_bottom_text">
            <span>邮件通知配置</span>
            <p>一站式邮件服务,为开发者聚合邮件接口.实现邮件查询</p>
          </div>
          <!-- <div>
            <span>开启邮箱提醒</span>
            <el-radio @input="closedopen" style="margin-left:30px;" v-model="form['mail.open']" label="0">关闭</el-radio>
            <el-radio @input="closedopen" v-model="form['mail.open']" label="1">开启</el-radio>
          </div> -->
          <el-form-item label-width="100px" label="开启邮箱提醒">
            <el-radio @input="closedopen" style="margin-left:30px;" v-model="form['mail.open']" :label="0">关闭</el-radio>
            <el-radio @input="closedopen" v-model="form['mail.open']" :label="1">开启</el-radio>
          </el-form-item>

          <el-form-item label-width="100px" label="发件人邮箱">
            <el-input v-model="form['mail.tencent_qq.user_mail']"></el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="授权码">
            <el-input v-model="form['mail.tencent_qq.send_pwd']"></el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="发件平台名称">
            <el-input v-model="form['mail.tencent_qq.user_name']"></el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="收件人邮箱">
            <el-input v-model="form['mail.to_mail']" class="diy--big"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <diy-footer-button v-if="hasPerm(['setting.trd_service.mail.update'])">
      <el-button type="primary" @click="edit">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        ['maill.type']: "0"
      },
    };
  },
  created() {
    this.mailinit();
  },
  methods: {
    closedopen(e) {
      console.log(e);
    },
    // 修改邮件
    edit() {
      this.$put(this.$apis.mailed, this.form)
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success"
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch(err => {
          this.common.message(this, err.message);
        });
    },
    mailinit() {
      this.$get(this.$apis.mailed)
        .then(res => {
          if (res.code == 200) {
            this.form = res.data;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch(err => {
          this.common.message(this, err.message);
        });
    },
    fbuton() {
      this.$router.push("/setup/setup_home");
    },
    butonalli(numindex) {
      if (numindex == 1) {
        this.$router.push("/setup/circulation/hundred");
      } else {
        this.$router.push("/setup/circulation/delivery");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;

    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }

  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 32px 56px 56px;
    box-sizing: border-box;

    p {
      width: 448px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    .textmessage_bottom_text {
      margin-bottom: 38px;
    }

    .iconconte {
      display: flex;
      margin: 20px;
      justify-content: flex-start;

      .iconconte_one {
        // position: relative;
        //   .el-radio{
        //   position: absolute;
        //   left: 500px;
        // }
        width: 280px;
        height: 74px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;
        display: flex;
        align-items: center;
        margin: 0 12px;

        img {
          margin-left: 30px;
          width: 49px;
          height: 53px;
        }

        .iconconte_text {
          margin-left: 33px;

          .Interface {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 5px;
            color: #0052d9;
            cursor: pointer;
          }
        }

        /deep/ .el-radio__label {
          display: none;
        }
      }
    }
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}
</style>